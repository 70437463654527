import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'

import _ from 'lodash'

// Style
import './ServicesOverview.scss'

const ServicesHome = () => {
  const {
    servicesItems: { edges: servicesItem },
  } = useStaticQuery(graphql`
    {
      servicesItems: allWordpressPage(
        filter: { parent_element: { wordpress_id: { eq: 26 } } }
        sort: { fields: acf___sortnumber, order: ASC }
      ) {
        edges {
          node {
            acf {
              service_title
              overview_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            path
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="col-lg-12 services d-flex flex-wrap">
        {_.map(servicesItem, (item, index) => (
          <div
            key={index}
            className="col-lg-3 col-md-6 p-0 pr-sm-0 pr-lg-3 pr-md-3 services-column text-center"
          >
            <Img
              className="mt-4"
              fluid={
                item.node.acf.overview_image.localFile.childImageSharp.fluid
              }
            />
            <h4 className="services-title py-3 text-center mx-auto text-uppercase position-relative">
              <Link to={item.node.path}>{item.node.acf.service_title}</Link>
            </h4>
          </div>
        ))}
      </div>
    </>
  )
}

export default ServicesHome
