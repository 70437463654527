import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import '../styles/templates/ServicesPage.scss'

// Components
import { TitleDefault } from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import ServicesOverview from 'components/Services/Overview'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        services_title
        header_image_services_page {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000){
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

function ServicesPage({ data: { page } }) {
  return (
    <Layout subFooter>
      {page.acf.header_image_services_page && page.acf.header_image_services_page.localFile ? (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} image={page.acf.header_image_services_page.localFile.childImageSharp.fixed.src} article />
      ) : (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} />
      )}
      <div className="services-template">
        <section className="heroimage position-relative d-flex">
          <div className="heroimage-image row position-absolute">
            <div className="col-12 col-lg-4 fill" />
            <div className="heroimage-image-container col-lg-8 p-0">
              <BackgroundImage
                className="bgimage"
                fluid={
                  page.acf.header_image_services_page.localFile.childImageSharp.fluid
                }
              />
            </div>
          </div>
        </section>
        <section className="services-row my-5">
          <div className="container">
            <div className="row">
              <TitleDefault className="text-uppercase">
                {page.acf.services_title}
              </TitleDefault>
              <ServicesOverview />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ServicesPage
